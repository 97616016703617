<template>
  <q-page class="flex flex-top">
    <div class="column full-width">
      <PageHeader />

      <div class="col">
        <div class="row fit q-py-lg">
          <div class="col col-lg-9 col-xl-7 q-px-md">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { PageHeader } from '../../components/pages'

export default {
  name: 'PERPage',
  components: { PageHeader },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      {}
    ])
  }
}
</script>
